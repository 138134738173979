import Image from 'mui-image'
import { Box, Container, Slide } from '@mui/material'
import { useState } from 'react'

import logo from '../assets/logo.svg'
import StageOne from '../components/StageOne'
import StageTwo from '../components/StageTwo'

export default function Home () {
  const [stage, setStage] = useState(1)

  return (
    <>
      <Box
        sx={{
          backgroundColor: '#25335E',
          minHeight: '100vh'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            paddingTop: '40px'
          }}
        >
          <Image src={logo} width={{ base: '50%', md: '15%' }} height='100%' />
        </Box>
        <Container
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            justifyContent: 'center'
          }}
          maxW='lg'
        >
          {stage === 1 && <StageOne setStage={setStage} />}
          {stage === 2 && <StageTwo setStage={setStage} />}
        </Container>
      </Box>
    </>
  )
}
