
import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'

import logo from '../assets/logo.svg'

export default function Navbar () {
  return (
    <Box
      sx={{
        position: 'static',
        width: '100%'
      }}
    >
      <Box sx={{ flexGrow: 1 }}>
        <>
          <AppBar
            sx={{
              backgroundColor: '#0C1757',
              position: 'static'
            }}
            elevation={0}
          >
            <Toolbar
              sx={{
                paddingRight: { md: '100px' },
                paddingLeft: { md: '100px' },
                paddingTop: '20px',
                paddingBottom: '20px'
              }}
            >
              <Box variant='h6' component='div' sx={{ flexGrow: 1, display: { xs: 'block', md: 'none' } }}>
                <img alt='' src={logo} width='50%' height='100%' />
              </Box>
              <Box variant='h6' component='div' sx={{ flexGrow: 1, display: { xs: 'none', md: 'block' } }}>
                <img alt='' src={logo} width='10%' height='100%' />
              </Box>
              <a style={{ scrollBehavior: 'smooth' }} href='#contactus'>
                <Button
                  sx={{
                    backgroundColor: '#F4933C',
                    color: 'white',
                    textTransform: 'none !important',
                    borderRadius: '0px'

                  }}
                >Contact Us
                </Button>
              </a>
            </Toolbar>
          </AppBar>
          {/* <Toolbar /> */}
        </>
      </Box>
      <Box
        sx={{
          paddingTop: '20px',
          paddingBottom: '10px',
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: '#F6C95D'
        }}
      >
        <Box
          sx={{
            width: '80%',
            textAlign: 'center',
            color: '#0C1757'
          }}
        >
          Eagle Club main website is under maintenance. But all booking services are available.
        </Box>
      </Box>
    </Box>
  )
}
