import { ConstructionOutlined } from '@mui/icons-material'
import * as Yup from 'yup'

export const FIRST_NAME = 'firstName'
export const FIRST_NAME_LABEL = 'First Name'

export const LAST_NAME = 'lastName'
export const LAST_NAME_LABEL = 'Last Name'

export const EMAIL_ADDRESS = 'emailAddress'
export const EMAIL_ADDRESS_LABEL = 'Email Address'

export const PHONE_NUMBER = 'phoneNumber'
export const PHONE_NUMBER_LABEL = 'Phone Number'

export const TYPE_EVENT = 'typeOfEvent'
export const TYPE_EVENT_LABEL = 'Type of Event'

export const NUMBER_OF_PEOPLE = 'noOfPeople'
export const NUMBER_OF_PEOPLE_LABEL = 'Number of Guests'

export const EVENT_SPACE = 'nameOfEventCenter'
export const EVENT_SPACE_LABEL = 'Event Space'

export const DATE_OF_EVENT = 'dateOfEvent'
export const DATE_OF_EVENT_LABEL = 'Date Of Event'

export default Yup.object({
  [FIRST_NAME]: Yup.string()
    .label(FIRST_NAME_LABEL)
    .required(),
  [LAST_NAME]: Yup.string()
    .label(LAST_NAME_LABEL)
    .required(),
  [TYPE_EVENT]: Yup.string()
    .label(TYPE_EVENT_LABEL)
    .required(),
  [NUMBER_OF_PEOPLE]: Yup.number()
    .label(NUMBER_OF_PEOPLE_LABEL)
    .required(),
  [EVENT_SPACE]: Yup.string()
    .label(EVENT_SPACE_LABEL)
    .required(),
  [PHONE_NUMBER]: Yup.string()
    .label(PHONE_NUMBER_LABEL).min(11).max(11)
    .required(),
  [EMAIL_ADDRESS]: Yup.string()
    .label(EMAIL_ADDRESS_LABEL).email('Invalid email')
    .required()
})
