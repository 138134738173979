import * as Yup from 'yup'

export const NAME = 'fullName'
export const NAME_LABEL = 'Full Name'

export const EMAIL = 'emailAddress'
export const EMAIL_LABEL = 'Email Address'

export const PHONE_NUMBER = 'phoneNumber'
export const PHONE_NUMBER_LABEL = 'Phone Number'

export const MESSAGE = 'message'
export const MESSAGE_LABEL = 'Message'

export default Yup.object({
  [NAME]: Yup.string()
    .label(NAME_LABEL)
    .required(),
  [PHONE_NUMBER]: Yup.string()
    .label(PHONE_NUMBER_LABEL).min(11).max(11)
    .required(),
  [EMAIL]: Yup.string()
    .label(EMAIL_LABEL).email('Invalid email')
    .required(),
  [MESSAGE]: Yup.string()
    .label(MESSAGE_LABEL).min(60).max(500)
    .required()
})
