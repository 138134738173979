import Hero from '../components/Hero'
import BookForm from '../components/BookForm'
import Contact from '../components/Contact'
import Navbar from '../components/Navbar'

export default function Home () {
  return (
    <>
      <Navbar />
      <Hero />
      <BookForm />
      <Contact />
    </>
  )
}
