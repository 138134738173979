import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Container from '@mui/material/Container'
import Button from '@mui/material/Button'
import MenuItem from '@mui/material/MenuItem'
import CircularProgress from '@mui/material/CircularProgress'
import axios from 'axios'
import { Notify } from 'notiflix/build/notiflix-notify-aio'
import { useState } from 'react'
import { Formik } from 'formik'
import * as React from 'react'
import dayjs from 'dayjs'
import Stack from '@mui/material/Stack'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'

import bookformValidator, {
  FIRST_NAME,
  FIRST_NAME_LABEL,
  LAST_NAME,
  LAST_NAME_LABEL,
  EMAIL_ADDRESS,
  EMAIL_ADDRESS_LABEL,
  PHONE_NUMBER,
  PHONE_NUMBER_LABEL,
  TYPE_EVENT,
  TYPE_EVENT_LABEL,
  NUMBER_OF_PEOPLE,
  NUMBER_OF_PEOPLE_LABEL,
  EVENT_SPACE,
  EVENT_SPACE_LABEL,
  DATE_OF_EVENT,
  DATE_OF_EVENT_LABEL
} from '../validators/bookformValidator'

const initialValues = {
  [FIRST_NAME]: '',
  [LAST_NAME]: '',
  [EMAIL_ADDRESS]: '',
  [PHONE_NUMBER]: '',
  [TYPE_EVENT]: '',
  [NUMBER_OF_PEOPLE]: '',
  [EVENT_SPACE]: '',
  [DATE_OF_EVENT]: ''
}

const eventSpaces = [
  {
    value: 'Marquee'
  },
  {
    value: 'Main Hall'
  },
  {
    value: 'Squash Hall'
  },
  {
    value: 'Parking Lot'
  },
  {
    value: 'Open Field'
  }
]

export default function BookForm () {
  const [loading, setLoading] = useState(false)
  const [dateError, setDateError] = useState(false)
  const [date, setDate] = useState(dayjs('2023-01-01T21:11:54'))

  const handleChangeTime = (newValue) => {
    if (dayjs(newValue).isBefore(dayjs())) {
      return setDateError(true)
    } else {
      setDateError(false)
    }
    setDate(newValue)
  }

  return (
    <Box
      sx={{
        paddingTop: '70px',
        paddingBottom: '70px'
      }}
    >
      <Box
        id='bookspace'
        sx={{
          textAlign: 'center',
          color: '#F4933C',
          fontSize: '40px',
          fontWeight: 700,
          paddingBottom: '50px'
        }}
      >
        Book A Space
      </Box>
      <Container maxWidth='lg'>
        <Formik
          initialValues={initialValues}
          validationSchema={bookformValidator}
          onSubmit={async (values, { isSubmitting, resetForm }) => {
            // await handleFormSubmit(values)
            setLoading(true)
            if (dayjs(date).isBefore(dayjs())) {
              setLoading(false)
              return setDateError(true)
            } else {
              setDateError(false)
            }

            const dataToSubmit = {
              ...values,
              dateOfEvent: dayjs(date).format('LLL')
            }

            try {
              await axios.post(
                `${process.env.REACT_APP_PROD_MODE === 'test'
                  ? process.env.REACT_APP_API_URL_TEST
                  : process.env.REACT_APP_API_URL_LIVE}/bookMarquee`,
                { ...dataToSubmit }
              )

              Notify.success('Booking request sent successfully')
            } catch (error) {
              Notify.failure(error?.response?.data?.errors[0].message)
            }
            setLoading(false)
            resetForm()
          }}
        >
          {({
            handleChange,
            handleSubmit,
            errors,
            touched,
            isSubmitting,
            setFieldValue,
            handleBlur,
            values,
            resetForm
          }) => (
            <form onSubmit={handleSubmit}>
              <Grid spacing={{ xs: 3, md: 6 }} container>
                <Grid item xs={12} md={6}>
                  <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: { xs: '100%' }
                  }}
                  >
                    <TextField
                      style={{ background: 'white' }}
                      name={FIRST_NAME}
                      fullWidth
                      values={values[FIRST_NAME]}
                      error={errors[FIRST_NAME] !== undefined}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder='First Name'
                      label={FIRST_NAME_LABEL}
                      variant='outlined'
                    />
                    <Box sx={{
                      color: 'red',
                      paddingTop: '6px',
                      fontSize: '11px'
                    }}
                    >{errors[FIRST_NAME]}
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: { xs: '100%' }
                  }}
                  >
                    <TextField
                      style={{ background: 'white' }}
                      name={LAST_NAME}
                      fullWidth
                      values={values[LAST_NAME]}
                      error={errors[LAST_NAME] !== undefined}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder='Last Name'
                      label={LAST_NAME_LABEL}
                      variant='outlined'
                    />
                    <Box sx={{
                      color: 'red',
                      paddingTop: '6px',
                      fontSize: '11px'
                    }}
                    >{errors[LAST_NAME]}
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: { xs: '100%' }
                  }}
                  >
                    <TextField
                      style={{ background: 'white' }}
                      name={EMAIL_ADDRESS}
                      fullWidth
                      values={values[EMAIL_ADDRESS]}
                      error={errors[EMAIL_ADDRESS] !== undefined}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder={EMAIL_ADDRESS_LABEL}
                      label={EMAIL_ADDRESS_LABEL}
                      variant='outlined'
                    />
                    <Box sx={{
                      color: 'red',
                      paddingTop: '6px',
                      fontSize: '11px'
                    }}
                    >{errors[EMAIL_ADDRESS]}
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: { xs: '100%' }
                  }}
                  >
                    <TextField
                      style={{ background: 'white' }}
                      name={PHONE_NUMBER}
                      fullWidth
                      error={errors[PHONE_NUMBER] !== undefined}
                      onBlur={handleBlur}
                      values={values[PHONE_NUMBER]}
                      onChange={handleChange}
                      placeholder={PHONE_NUMBER_LABEL}
                      label={PHONE_NUMBER_LABEL}
                      variant='outlined'
                    />
                    <Box sx={{
                      color: 'red',
                      paddingTop: '6px',
                      fontSize: '11px'
                    }}
                    >{errors[PHONE_NUMBER]}
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: { xs: '100%' }
                  }}
                  >
                    <TextField
                      style={{ background: 'white' }}
                      name={TYPE_EVENT}
                      fullWidth
                      error={errors[TYPE_EVENT] !== undefined}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      values={values[TYPE_EVENT]}
                      placeholder={TYPE_EVENT_LABEL}
                      label={TYPE_EVENT_LABEL}
                      variant='outlined'
                    />
                    <Box sx={{
                      color: 'red',
                      paddingTop: '6px',
                      fontSize: '11px'
                    }}
                    >{errors[TYPE_EVENT]}
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: { xs: '100%' }
                  }}
                  >
                    <TextField
                      type='number'
                      style={{ background: 'white' }}
                      name={NUMBER_OF_PEOPLE}
                      fullWidth
                      error={errors[NUMBER_OF_PEOPLE] !== undefined}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      values={values[NUMBER_OF_PEOPLE]}
                      placeholder={NUMBER_OF_PEOPLE_LABEL}
                      label={NUMBER_OF_PEOPLE_LABEL}
                      variant='outlined'
                    />
                    <Box sx={{
                      color: 'red',
                      paddingTop: '6px',
                      fontSize: '11px'
                    }}
                    >{errors[NUMBER_OF_PEOPLE]}
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: { xs: '100%' }
                  }}
                  >
                    <TextField
                      select
                      style={{ background: 'white' }}
                      name={EVENT_SPACE}
                      fullWidth
                      error={errors[EVENT_SPACE] !== undefined}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      values={values[EVENT_SPACE]}
                      placeholder={EVENT_SPACE_LABEL}
                      label={EVENT_SPACE_LABEL}
                      variant='outlined'
                    >
                      {eventSpaces.map((option, index) => (
                        <MenuItem key={index} value={option.value}>
                          {option.value}
                        </MenuItem>
                      ))}
                    </TextField>
                    <Box sx={{
                      color: 'red',
                      paddingTop: '6px',
                      fontSize: '11px'
                    }}
                    >{errors[EVENT_SPACE]}
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: { xs: '100%' }
                  }}
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateTimePicker
                        label='Date&Time picker'
                        onChange={handleChangeTime}
                        value={date}
                        error={dateError}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                    {/* <TextField
                      select
                      style={{ background: 'white' }}
                      name={EVENT_SPACE}
                      fullWidth
                      error={errors[EVENT_SPACE] !== undefined}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      values={values[EVENT_SPACE]}
                      placeholder={EVENT_SPACE_LABEL}
                      label={EVENT_SPACE_LABEL}
                      variant='outlined'
                    >
                      {eventSpaces.map((option, index) => (
                        <MenuItem key={index} value={option.value}>
                          {option.value}
                        </MenuItem>
                      ))}
                    </TextField> */}
                    <Box sx={{
                      color: 'red',
                      paddingTop: '6px',
                      fontSize: '11px'
                    }}
                    >{dateError && 'Please enter valid date'}
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <Box
                sx={{ paddingTop: '20px' }}
              >
                <Button
                  disabled={loading}
                  type='submit'
                  disableElevation
                  sx={{
                    textTransform: 'none',
                    backgroundColor: '#F4933C',
                    borderRadius: '0px'
                  }}
                  variant='contained'
                >
                  {loading ? <CircularProgress /> : 'Submit Booking'}
                </Button>
              </Box>
            </form>
          )}
        </Formik>
        <Box
          sx={{
            paddingTop: '20px',
            fontSize: '11px',
            color: '#979797'
          }}
        >
          A member from our Booking team will reach out to you as soon as possible.
        </Box>
      </Container>
    </Box>
  )
}
