import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import '@fontsource/inter'

import Home from './page/Home'
import FlagFootball from './page/FlagFootball'
import './App.css'

function App () {
  return (
    <Box>
      <CssBaseline />
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/flag-football' element={<FlagFootball />} />
        </Routes>
      </BrowserRouter>
    </Box>
  )
}

export default App
