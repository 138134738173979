import Box from '@mui/material/Box'
import Button from '@mui/material/Button'

import backgroundImage from '../assets/backgroundImage.png'

export default function Hero () {
  return (
    <Box
      sx={{
        minHeight: { xs: '70vh', md: '100vh' },
        backgroundImage: `url(${backgroundImage})`,
        height: '100%',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
      }}
    >
      <Box
        sx={{
          paddingTop: { xs: '180px', md: '200px' },
          color: 'white',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <Box
          sx={{
            fontSize: '35px',
            fontWeight: 500
          }}
        >
          Eagle Club is back
        </Box>
        <Box
          sx={{
            paddingTop: '20px',
            color: '#A9CD3B',
            fontWeight: 800,
            fontSize: { xs: '30px', md: '70px' },
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            lineHeight: '100%'
          }}
        >
          <Box>
            We taking leisure to  <br />the next level
          </Box>
        </Box>
        <Box
          sx={{ paddingTop: '20px' }}
        >
          <a href='#bookspace'>
            <Button
              disableElevation
              sx={{
                textTransform: 'none',
                backgroundColor: '#F4933C',
                borderRadius: '0px',
                position: 'static'
              }}
              variant='contained'
            >
              Book A Space
            </Button>
          </a>
        </Box>
      </Box>
    </Box>
  )
}
