import React, { useState } from 'react'
import { Box, Button, TextField, Stack, Slide } from '@mui/material'
import axios from 'axios'
import * as Yup from 'yup' // Import Yup
import { Notify } from 'notiflix/build/notiflix-notify-aio'

import flagFootballValidator, {
  FIRST_NAME,
  FIRST_NAME_LABEL,
  LAST_NAME,
  LAST_NAME_LABEL,
  EMAIL,
  EMAIL_LABEL,
  PHONE_NUMBER,
  PHONE_NUMBER_LABEL
} from '../validators/flagFootballValidator'

import { usePaystackPayment } from 'react-paystack'

const validationSchema = Yup.object().shape({
  [FIRST_NAME]: Yup.string().required('First Name is required'),
  [LAST_NAME]: Yup.string().required('Last Name is required'),
  [EMAIL]: Yup.string().email('Invalid email').required('Email is required'),
  [PHONE_NUMBER]: Yup.string().required('Phone Number is required')
})

// const config = {
//   reference: `flagFoot${new Date().getTime().toString()}`,
//   email: 'kevocip120@docwl.com',
//   amount: 20000,
//   publicKey: 'pk_test_ef9a66acb3b1e481a64fcccd85aa909d93aeb6b9'
// }

// const onSuccess = async reference => {
//   console.log('this isdkf', reference)
//   try {
//     const { res } = await axios(`${NEXT_PUBLIC_API_URL_LIVE_FLAGFOOTBAL}`, {
//       ...formData,
//       reference
//     })
//   } catch (error) {
//     Notify.failure('Payment Failed')
//   }
// }

// const onClose = () => {
//   console.log('closed')
// }

// you can call this function anything

// you can call this function anything
const onClose = () => {
  // implementation for  whatever you want to do when the Paystack dialog closed.
  console.log('closed')
}

console.log('this gkfbfd', process.env.NEXT_PUBLIC_API_URL_LIVE_FLAGFOOTBALL)

export default function StageTwo ({ setStage }) {
  const [formData, setFormData] = useState({
    [FIRST_NAME]: '',
    [LAST_NAME]: '',
    [EMAIL]: '',
    [PHONE_NUMBER]: ''
  })

  const submitForm = async reference => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL_LIVE_FLAGFOOTBALL}`,
        {
          ...formData,
          reference: reference.reference
        }
      )

      Notify.success(data.message)

      setFormData({
        [FIRST_NAME]: '',
        [LAST_NAME]: '',
        [EMAIL]: '',
        [PHONE_NUMBER]: ''
      })
    } catch (error) {
      Notify.failure('Server error, please try again')
    }
  }

  const onSuccess = reference => {
    // Implementation for whatever you want to do with reference and after success call.
    submitForm(reference)
  }

  const config = {
    reference: `flagFoot${new Date().getTime().toString()}`,
    email: formData?.email,
    amount: 200000,
    publicKey: 'pk_live_9abcd467f2ae6c62ffb138e69e397d4139c25c50'
  }

  const initializePayment = usePaystackPayment(config)

  const handlePaymentClick = async () => {
    try {
      await validationSchema.validate(formData, { abortEarly: false })
      initializePayment(onSuccess, onClose)
    } catch (errors) {
      console.log('this is fhfg', errors)
      errors?.errors?.forEach(msg => Notify.failure(msg))
    }
  }

  const handleInputChange = event => {
    const { name, value } = event.target
    setFormData({
      ...formData,
      [name]: value
    })
  }

  return (
    <Slide direction='left' in>
      <Box pt='170px'>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            fontWeight: 900,
            fontSize: '25px',
            color: '#F4933C'
          }}
        >
          Register
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            paddingTop: '20px',
            paddingBottom: '80px',
            transition: 'all 1s ease-out'
          }}
        >
          <Box
            component='form'
            sx={{
              width: '100'
            }}
            noValidate
            autoComplete='off'
          >
            <Stack width='100%' spacing={4}>
              <TextField
                fullWidth
                id={FIRST_NAME}
                name={FIRST_NAME}
                placeholder={FIRST_NAME_LABEL}
                value={formData[FIRST_NAME]}
                onChange={handleInputChange}
                variant='outlined'
                sx={{
                  '& .MuiInputBase-input::placeholder': {
                    color: '#F4933C' // Change this to the desired color
                  },
                  color: '#F4933C',
                  width: { xs: '300px', md: '500px' }
                }}
                InputProps={{
                  style: {
                    color: '#F4933C',
                    backgroundColor: 'white',
                    '::placeholder': {
                      color: '#F4933C' // Set the desired placeholder color
                    }
                  }
                }}
                InputLabelProps={{
                  style: {
                    color: '#F4933C' // Change this to the desired placeholder text color
                  }
                }}
              />
              <TextField
                id={LAST_NAME}
                name={LAST_NAME}
                placeholder={LAST_NAME_LABEL}
                value={formData[LAST_NAME]}
                onChange={handleInputChange}
                variant='outlined'
                sx={{
                  '& .MuiInputBase-input::placeholder': {
                    color: '#F4933C' // Change this to the desired color
                  },
                  color: '#F4933C',
                  width: { xs: '300px', md: '500px' }
                }}
                InputProps={{
                  style: {
                    color: '#F4933C',
                    backgroundColor: 'white',
                    '::placeholder': {
                      color: '#F4933C' // Set the desired placeholder color
                    }
                  }
                }}
                InputLabelProps={{
                  style: {
                    color: '#F4933C' // Change this to the desired placeholder text color
                  }
                }}
              />
              <TextField
                id={EMAIL}
                name={EMAIL}
                placeholder={EMAIL_LABEL}
                value={formData[EMAIL]}
                onChange={handleInputChange}
                variant='outlined'
                sx={{
                  '& .MuiInputBase-input::placeholder': {
                    color: '#F4933C' // Change this to the desired color
                  },
                  color: '#F4933C',
                  width: { xs: '300px', md: '500px' }
                }}
                InputProps={{
                  style: {
                    color: '#F4933C',
                    backgroundColor: 'white',
                    '::placeholder': {
                      color: '#F4933C' // Set the desired placeholder color
                    }
                  }
                }}
                InputLabelProps={{
                  style: {
                    color: '#F4933C' // Change this to the desired placeholder text color
                  }
                }}
              />
              <TextField
                id={PHONE_NUMBER}
                name={PHONE_NUMBER}
                placeholder={PHONE_NUMBER_LABEL}
                value={formData[PHONE_NUMBER]}
                onChange={handleInputChange}
                variant='outlined'
                sx={{
                  '& .MuiInputBase-input::placeholder': {
                    color: '#F4933C' // Change this to the desired color
                  },
                  color: '#F4933C',
                  width: { xs: '300px', md: '500px' }
                }}
                InputProps={{
                  style: {
                    color: '#F4933C',
                    backgroundColor: 'white',
                    '::placeholder': {
                      color: '#F4933C' // Set the desired placeholder color
                    }
                  }
                }}
                InputLabelProps={{
                  style: {
                    color: '#F4933C' // Change this to the desired placeholder text color
                  }
                }}
              />
              {/* Repeat for other fields */}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%'
                }}
              >
                <Button
                  sx={{
                    backgroundColor: '#68D391',
                    color: 'white',
                    width: { xs: '200px', md: '500px' },
                    '&:Hover': {
                      backgroundColor: '#68D391',
                      color: 'white'
                    }
                  }}
                  // type='submit'
                  onClick={handlePaymentClick}
                >
                  Pay Now
                </Button>
              </Box>
            </Stack>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
                paddingTop: '10px'
              }}
            >
              <Button
                sx={{
                  backgroundColor: '#4A5568',
                  color: 'white',
                  width: { xs: '200px', md: '500px' },
                  '&:Hover': {
                    backgroundColor: '#4A5568',
                    color: 'white'
                  }
                }}
                onClick={() => setStage(1)}
              >
                Back Now
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Slide>
  )
}
