import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import axios from 'axios'
import CircularProgress from '@mui/material/CircularProgress'
import { Notify } from 'notiflix/build/notiflix-notify-aio'
import { useState } from 'react'
import { Formik } from 'formik'

import phone from '../assets/phone.svg'
import location from '../assets/location.svg'
import mail from '../assets/mail.svg'
import contactFormValidator, {
  NAME,
  NAME_LABEL,
  EMAIL,
  EMAIL_LABEL,
  MESSAGE,
  MESSAGE_LABEL,
  PHONE_NUMBER,
  PHONE_NUMBER_LABEL
} from '../validators/contactFormValidator'

const initialValues = {
  [NAME]: '',
  [EMAIL]: '',
  [MESSAGE]: '',
  [PHONE_NUMBER]: ''
}

export default function Contact () {
  const [loading, setLoading] = useState(false)

  return (
    <Box
      sx={{
        backgroundColor: '#F7F7F7'
      }}
    >
      <Container
        maxWidth='lg'
      >
        <Box
          sx={{
            paddingTop: '70px',
            paddingBottom: '70px'
          }}
        >
          <Box
            id='contactus'
            sx={{
              textAlign: 'center',
              color: '#F4933C',
              fontSize: '40px',
              fontWeight: 700,
              paddingBottom: '50px'
            }}
          >
            Contact Us
          </Box>
          <Box
            sx={{
              display: { md: 'flex' },
              justifyContent: { md: 'space-between' }
            }}
          >
            <Box
              sx={{
                paddingTop: '30px'
              }}
            >
              <Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: { xs: 'center', md: 'flex-start' },
                    fontWeight: 700,
                    fontSize: '30px',
                    color: '#0C1757'
                  }}
                >
                  Get in Touch
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: { xs: 'center', md: 'flex-start' },
                    paddingTop: '20px',
                    color: '#979797',
                    fontWeight: 400,
                    fontSize: '15px',
                    textAlign: { xs: 'center', md: 'start' }
                  }}
                >
                  <Box
                    sx={{
                      width: '80%'
                    }}
                  >
                    We appreciate each customer and we are proud that 80% of customers come back to have great time.
                  </Box>
                </Box>
                <Box
                  sx={{ paddingTop: '30px' }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: { xs: 'center', md: 'flex-start' },
                      color: '#0C1757',
                      fontWeight: 500
                    }}
                  >
                    <Box
                      sx={{ paddingRight: '15px' }}
                    >
                      <img alt='' src={phone} width='100%' height='100%' />
                    </Box>
                    <Box
                      sx={{ fontSize: '12px' }}
                    >+234 80 9882 8556
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: { xs: 'center', md: 'flex-start' },
                      paddingTop: '15px',
                      color: '#0C1757',
                      fontWeight: 500
                    }}
                  >
                    <Box
                      sx={{ paddingRight: '15px' }}
                    >
                      <img alt='' src={mail} width='100%' height='100%' />
                    </Box>
                    <Box
                      sx={{ fontSize: '12px' }}
                    >contact@eagleclublagos.com
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: { xs: 'center', md: 'flex-start' },
                      paddingTop: '15px',
                      color: '#0C1757',
                      fontWeight: 500
                    }}
                  >
                    <Box
                      sx={{ paddingRight: '15px' }}
                    >
                      <img alt='' src={location} width='100%' height='100%' />
                    </Box>
                    <Box
                      sx={{ fontSize: '12px' }}
                    >96 Adeniran Ogunsanya Street, <br /> Surulere 101241, Lagos
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box>
                <Box
                  sx={{
                    paddingTop: '25px',
                    paddingBottom: '20px',
                    fontSize: '15px',
                    color: '#0C1757',
                    fontWeight: 500
                  }}
                >Find Us on Map
                </Box>
                <Box sx={{ width: { md: '70%' } }}>
                  <iframe class='gmap_iframe' scrolling='no' title='location' src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3964.239440063107!2d3.353641014822291!3d6.491336625337195!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b8c0d97c0170b%3A0xf429604a5d78ea4b!2sEagle%20Club!5e0!3m2!1sen!2sus!4v1668759180916!5m2!1sen!2sus' width='100%' style={{ border: '0' }} allowfullscreen='' loading='lazy' referrerpolicy='no-referrer-when-downgrade' />
                </Box>
              </Box>
            </Box>
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: { xs: 'center', md: 'flex-start' },
                  fontWeight: 700,
                  fontSize: '30px',
                  color: '#0C1757',
                  paddingTop: '30px'
                }}
              >
                Send A Message
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: { xs: 'center', md: 'flex-start' },
                  paddingTop: '20px',
                  paddingBottom: '20px',
                  color: '#979797',
                  fontWeight: 400,
                  fontSize: '15px',
                  textAlign: { xs: 'center', md: 'start' }
                }}
              >
                <Box
                  sx={{
                    width: '80%'
                  }}
                >
                  We care about your opinions and we want to ensure you always have a great time with us
                </Box>
              </Box>
              <Formik
                initialValues={initialValues}
                validationSchema={contactFormValidator}
                onSubmit={async (values, { isSubmitting, resetForm }) => {
                  // await handleFormSubmit(values)

                  setLoading(true)

                  try {
                    await axios.post(
                      `${process.env.REACT_APP_PROD_MODE === 'test'
                        ? process.env.REACT_APP_API_URL_TEST
                        : process.env.REACT_APP_API_URL_LIVE}/contactus`,
                      { ...values }
                    )

                    Notify.success('Mail sent succesfully')
                  } catch (error) {
                    Notify.success(error?.response?.data?.errors[0].message)
                  }
                  setLoading(false)
                  resetForm()
                }}
              >
                {({
                  handleChange,
                  handleSubmit,
                  errors,
                  touched,
                  isSubmitting,
                  setFieldValue,
                  handleBlur,
                  values
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Grid spacing={{ xs: 3, md: 6 }} container>
                      <Grid item xs={12} md={12}>
                        <Box sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          width: { xs: '100%' }
                        }}
                        >
                          <TextField
                            style={{ background: 'white' }}
                            name={NAME}
                            fullWidth
                            error={errors[NAME] !== undefined}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder='First Name Last Name'
                            label={NAME_LABEL}
                            variant='outlined'
                          />
                          <Box sx={{
                            color: 'red',
                            paddingTop: '6px',
                            fontSize: '11px'
                          }}
                          >{errors[NAME]}
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <Box sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          width: { xs: '100%' }
                        }}
                        >
                          <TextField
                            style={{ background: 'white' }}
                            name={EMAIL}
                            fullWidth
                            error={errors[EMAIL] !== undefined}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder={EMAIL_LABEL}
                            label={EMAIL_LABEL}
                            variant='outlined'
                          />
                          <Box sx={{
                            color: 'red',
                            paddingTop: '6px',
                            fontSize: '11px'
                          }}
                          >{errors[EMAIL]}
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          width: { xs: '100%' }
                        }}
                        >
                          <TextField
                            style={{ background: 'white' }}
                            name={PHONE_NUMBER}
                            fullWidth
                            error={errors[PHONE_NUMBER] !== undefined}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder={PHONE_NUMBER_LABEL}
                            label={PHONE_NUMBER_LABEL}
                            variant='outlined'
                          />
                          <Box sx={{
                            color: 'red',
                            paddingTop: '6px',
                            fontSize: '11px'
                          }}
                          >{errors[PHONE_NUMBER]}
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          width: { xs: '100%' }
                        }}
                        >
                          <TextField
                            style={{ background: 'white' }}
                            name={MESSAGE}
                            fullWidth
                            error={errors[MESSAGE] !== undefined}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            multiline
                            minRows={8}
                            placeholder={MESSAGE_LABEL}
                            label={MESSAGE_LABEL}
                            variant='outlined'
                          />
                          <Box sx={{
                            color: 'red',
                            paddingTop: '6px',
                            fontSize: '11px'
                          }}
                          >{errors[MESSAGE]}
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                    <Box
                      sx={{ paddingTop: '20px' }}
                    >
                      <Button
                        type='submit'
                        disabled={loading}
                        disableElevation
                        sx={{
                          textTransform: 'none',
                          backgroundColor: '#F4933C',
                          borderRadius: '0px'
                        }}
                        variant='contained'
                      >
                        {loading ? <CircularProgress /> : 'Send'}
                      </Button>
                    </Box>
                  </form>
                )}
              </Formik>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}
