import * as Yup from 'yup'

export const FIRST_NAME = 'firstName'
export const FIRST_NAME_LABEL = 'First Name'

export const LAST_NAME = 'lastName'
export const LAST_NAME_LABEL = 'Last Name'

export const EMAIL = 'email'
export const EMAIL_LABEL = 'Email Address'

export const PHONE_NUMBER = 'phoneNumber'
export const PHONE_NUMBER_LABEL = 'Phone Number'

export default Yup.object({
  [FIRST_NAME]: Yup.string().label(FIRST_NAME_LABEL).required(),
  [LAST_NAME]: Yup.string().label(FIRST_NAME_LABEL).required(),
  [PHONE_NUMBER]: Yup.string()
    .label(PHONE_NUMBER_LABEL)
    .min(11)
    .max(11)
    .required(),
  [EMAIL]: Yup.string()
    .label(EMAIL_LABEL)
    .email('Invalid email address')
    .required('Email is required')
})
