import Image from 'mui-image'
import { useEffect } from 'react'
import { Box, Stack, Button } from '@mui/material'

import poster from '../assets/poster.jpeg'
import leisure from '../assets/leisure.jpeg'
import AOS from 'aos'
import 'aos/dist/aos.css'

export default function StageOne ({ setStage }) {
  useEffect(() => {
    AOS.init()
  }, [])

  return (
    <Box
      sx={{
        minHeight: '100vh',
        paddingBottom: '60px'
      }}
    >
      <Box>
        <Box
          sx={{
            display: { md: ' flex' },
            justifyContent: { md: 'center' }
          }}
          pt='60px'
        >
          <Stack spacing={2}>
            <Stack
              direction='column'
              spacing={0.3}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                color: '#A9CD3B',
                fontSize: '13px'
              }}
            >
              <Box
                data-aos='zoom-in'
                sx={{
                  fontWeight: 900,
                  fontSize: '30px',
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                Flag Football
              </Box>
            </Stack>
            <Stack
              direction='column'
              spacing={0.3}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                color: '#A9CD3B',
                fontSize: '13px'
              }}
            >
              <Box
                data-aos='zoom-in'
                sx={{
                  fontWeight: 900,
                  fontSize: '20px',
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                Entrance fee:
              </Box>
              <Box
                data-aos='zoom-in'
                sx={{
                  display: 'flex',
                  justifyContent: 'center'
                }}
                fontSize='15px'
              >
                Free
              </Box>
            </Stack>
            <Stack
              direction='column'
              spacing={0.3}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                color: '#A9CD3B',
                fontSize: '13px'
              }}
            >
              <Box
                data-aos='zoom-in'
                sx={{
                  fontWeight: 900,
                  fontSize: '20px',
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                Time:
              </Box>
              <Box
                data-aos='zoom-in'
                sx={{
                  display: 'flex',
                  justifyContent: 'center'
                }}
                fontSize='15px'
              >
                10am to 12 noon
              </Box>
            </Stack>
          </Stack>
        </Box>
        <Box
          pt='30px'
          sx={{
            display: 'flex',
            justifyContent: { xs: 'center' }
          }}
        >
          <Image src={poster} width='50%' height='100%' />
        </Box>
      </Box>
      <Box>
        <Box
          pt='30px'
          sx={{
            display: 'flex',
            justifyContent: { xs: 'center' }
          }}
        >
          <Image src={leisure} width='50%' height='100%' />
        </Box>
        <Box
          sx={{
            alignSelf: 'center',
            display: { md: ' flex' },
            justifyContent: { xs: 'center' }
          }}
          pt='60px'
        >
          <Stack spacing={2}>
            <Stack
              direction='column'
              spacing={0.3}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                color: '#A9CD3B',
                fontSize: '13px'
              }}
            >
              <Box
                data-aos='zoom-in'
                sx={{
                  fontWeight: 900,
                  fontSize: '30px',
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                Leisure Therapy
              </Box>
            </Stack>
            <Stack
              direction='column'
              spacing={0.3}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                color: '#A9CD3B',
                fontSize: '13px'
              }}
            >
              <Box
                data-aos='zoom-in'
                sx={{
                  fontWeight: 900,
                  fontSize: '20px',
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                Entrance fee:
              </Box>
              <Box
                data-aos='zoom-in'
                sx={{
                  display: 'flex',
                  justifyContent: 'center'
                }}
                fontSize='15px'
              >
                ₦2,000
              </Box>
            </Stack>
            <Stack
              direction='column'
              spacing={0.3}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                color: '#A9CD3B',
                fontSize: '13px'
              }}
            >
              <Box
                data-aos='zoom-in'
                sx={{
                  fontWeight: 900,
                  fontSize: '20px',
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                Time:
              </Box>
              <Box
                data-aos='zoom-in'
                sx={{
                  display: 'flex',
                  justifyContent: 'center'
                }}
                fontSize='15px'
              >
                12 noon to 6pm
              </Box>
            </Stack>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
                paddingBottom: '50px'
              }}
            >
              <Button
                onClick={() => {
                  setStage(2)
                }}
                sx={{
                  backgroundColor: '#F4933C',
                  textTransform: 'none',
                  color: 'white',
                  '&:Hover': {
                    backgroundColor: '#F4933C'
                  }
                }}
                width='70%'
                variant='contained'
              >
                Register Now
              </Button>
            </Box>
          </Stack>
        </Box>
      </Box>
    </Box>
  )
}
